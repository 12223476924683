/* eslint-disable vue/no-side-effects-in-computed-properties */
<script>
import Layout from "../layouts/main";
import appConfig from "@/app.config";


/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      title: "Dashboard"
    }
  },
  computed: {
      orders() { return this.$store.getters['order/items'] },
      customer() { return this.$store.getters['customer/items'] },
  },
  mounted(){
    this.$store.dispatch("order/get")
    this.$store.dispatch("customer/get")
  }

};
</script>

<template>
  <Layout>
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">Dashboard</h4>

          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item active">Tanggal : 05-Apr-2021 | Pukul : 03:35:34 , Selamat Malam</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
